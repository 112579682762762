import React from 'react';
import { Provider } from 'react-redux';
import store from './index';

export default ({ element }) => {
  //const store = createStore();
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
};

