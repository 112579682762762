/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import wrapWithProvider from "./state/ReduxWrapper"
//export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}

// export const onPreRouteUpdate = ({ location, prevLocation }) => {
//   console.log("Gatsby started to change location to", location.pathname)
//   console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
// }

export { default as wrapRootElement } from './state/ReduxWrapper'; 


export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  //return <Layout {...props}>{element}</Layout>
}